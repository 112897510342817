import { signIn, signOut, getSession } from "next-auth/react";

import { useStore } from "@store/index";
import type { StoreState } from "@store/index";
import { useErrorToast } from "@lib/utils/store-utility/storeUtility";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { useGetMyActiveData } from "@store/hooks";
import { CommonCMS } from "@lib/constants/contentful";

const useSignOut = () => {
    const store = useStore<StoreState>();
    const toast = useErrorToast();
    const { get: getMicrocopy } = useMicrocopy();
    const executeGetMyActiveData = useGetMyActiveData();

    const anonymousLogIn = async () => {
        try {
            await signIn("anonymous", { redirect: false });
            await getSession();
        } catch {
            toast({
                title: getMicrocopy(CommonCMS.global, CommonCMS.error.general.title),
                description: getMicrocopy(CommonCMS.global, CommonCMS.error.general.description),
            });
        }
    };

    async function signOutHandler(): Promise<void> {
        store.clearWishlist();
        signOut({ redirect: false });
        store.setIsLoggedIn(false);
        await anonymousLogIn();
        await executeGetMyActiveData({});
    }

    return { signOut: signOutHandler };
};

export default useSignOut;
