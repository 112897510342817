import type {
    ChangeProductQuantityInCartMutationVariables,
    ChangeProductSizeInCartMutationVariables,
    ChangeProductSizeToExistOneInCartMutationVariables,
    EccoAddProductToCartMutationVariables,
    EccoRemoveProductFromCartMutationVariables,
} from "@graphql/generated/components";
import {
    useChangeProductQuantityInCartMutation,
    useChangeProductSizeInCartMutation,
    useChangeProductSizeToExistOneInCartMutation,
    useEccoAddProductToCartMutation,
    useEccoRemoveProductFromCartMutation,
} from "@graphql/generated/components";
import { updateMutation } from "../helpers";
import { useStore } from "../store";

export const useAddProductToCart = () => {
    const [_, execute] = useEccoAddProductToCartMutation();
    const setData = useStore((state) => state.setCart);
    return updateMutation<EccoAddProductToCartMutationVariables>(execute, setData);
};

export const useRemoveProductFromCart = () => {
    const [_, execute] = useEccoRemoveProductFromCartMutation();
    const setData = useStore((state) => state.setCart);
    return updateMutation<EccoRemoveProductFromCartMutationVariables>(execute, setData);
};

export const useUpdateProductQtyInCart = () => {
    const [_, execute] = useChangeProductQuantityInCartMutation();
    const setData = useStore((state) => state.setCart);
    return updateMutation<ChangeProductQuantityInCartMutationVariables>(execute, setData);
};

export const useUpdateProductSizeInCart = () => {
    const [_, execute] = useChangeProductSizeInCartMutation();
    const setData = useStore((state) => state.setCart);
    return updateMutation<ChangeProductSizeInCartMutationVariables>(execute, setData);
};

export const useUpdateProductSizeToExistOneInCart = () => {
    const [_, execute] = useChangeProductSizeToExistOneInCartMutation();
    const setData = useStore((state) => state.setCart);
    return updateMutation<ChangeProductSizeToExistOneInCartMutationVariables>(execute, setData);
};
