import type { ButtonProps } from "@ui/components/forms/button/Button";

export const pageNavigationLinkStyles = (isActive: boolean): ButtonProps["sx"] => {
    return {
        textDecoration: "none",
        _hover: isActive
            ? {
                  textDecoration: "none",
                  cursor: "default",
              }
            : {
                  textDecoration: "none",
                  color: "gray.900",
              },
        fontWeight: "bold",
        flexShrink: 0,
        backgroundColor: isActive ? "gray.800" : "white",
        color: isActive ? "white" : "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "full",
        h: 10,
        px: 3,
        py: 4,
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
        fontSize: ["desktopBodyB2", "mobileBodyB2"],
        mb: 3,
    };
};

export const nondisplayedLink = (isActive: boolean): ButtonProps["sx"] => ({
    textDecoration: "none",
    _hover: isActive
        ? {
              textDecoration: "none",
              cursor: "default",
          }
        : {
              textDecoration: "none",
              color: "gray.900",
          },
    fontWeight: "semibold",
    minW: "24",
    flexShrink: 0,
    backgroundColor: isActive ? "black" : "unset",
    color: isActive ? "white" : "black",
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    py: "2",
    px: "4",
    borderRadius: "full",
});
