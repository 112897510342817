import type { EccoShippingMethodsByCartQueryVariables } from "@graphql/generated/components";
import { EccoShippingMethodsByCartDocument } from "@graphql/generated/components";
import { useClient } from "urql";
import { updateQuery } from "../helpers";
import { useStore } from "../store";

export const useShippingMethodsByCart = () => {
    const client = useClient();
    const setData = useStore((state) => state.setShippingMethods);

    const selector = (data) => {
        return data[Object.keys(data)[0]];
    };

    return updateQuery<EccoShippingMethodsByCartQueryVariables>(
        client.query,
        setData,
        selector,
        EccoShippingMethodsByCartDocument,
        { cartId: "$cartId" }
    );
};
