import type { ILinkProps } from "@components/cms/link/Link";
import { Link } from "@components/cms/link/Link";
import { forwardRef } from "react";
import { nondisplayedLink, pageNavigationLinkStyles } from "./style";

type Props = {
    isActive: boolean;
    isAddressesLink?: boolean;
} & ILinkProps;

export const PageNavLink = forwardRef<
    HTMLElement & HTMLParagraphElement & HTMLAnchorElement,
    Props
>(function PageNavLink({ isActive, isAddressesLink, link, onClick }, ref) {
    return (
        <Link
            sx={isAddressesLink ? nondisplayedLink(isActive) : pageNavigationLinkStyles(isActive)}
            link={link}
            onClick={onClick}
            _active={{ color: "white", backgroundColor: "gray.800" }}
            ref={isActive ? ref : null}
        />
    );
});
