import { useStoreApi } from "@store/store";

/**
 * Will set isLocked on store.data.isLocked and run a wrapped function.
 *
 * This can be used for disabling buttons and whatever else will manipulate the current cart.
 * We do this to avoid cart version mismatch, which will happen if multiple operations
 * are executed on the same cart version.
 *
 * Subscribers can listen to store.data.isLocked
 */
export const useLock = () => {
    const api = useStoreApi();

    const unlock = () => {
        api.setState((state) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    isLocked: false,
                },
            };
        });
    };

    return (fn: (...args: any[]) => any): any => {
        return async (...args: any) => {
            // We set the isLocked state outside the React render cycle,
            // so we don't use setLocked.
            api.setState((state) => {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        isLocked: true,
                    },
                };
            });

            try {
                const result = await fn(...args);
                unlock();
                return result;
            } catch (error) {
                unlock();
                throw error;
            }
        };
    };
};
