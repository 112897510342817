import { renderRichTextToComponent } from "@components/cms/rich-text-renderer/renderRichTextToComponent";
import type { IFooter } from "@contentful-api/types/contentful";
import { colors } from "@ui/design-tokens";
import { AnalyticsClient } from "@lib/analytics/AnalyticsClient";
import { CommonCMS, commonReplacements } from "@lib/constants/contentful";
import Consent from "@lib/constants/contentful/consent";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import FetchService from "@lib/helpers/fetchService";
import { removeEmptyContent } from "@lib/utils/cleanContent";
import { useErrorToast } from "@lib/utils/store-utility/storeUtility";
import { checkValidEmailDomain } from "@lib/validators/user/logInSchema";
import { Fade } from "@ui/components/feedback/fade/Fade";
import { Button } from "@ui/components/forms/button/Button";
import { Checkbox } from "@ui/components/forms/checkbox/Checkbox";
import { Field } from "@ui/components/forms/field/Field";
import { FormControl } from "@ui/components/forms/form-control/FormControl";
import { FormErrorMessage } from "@ui/components/forms/form-control/FormError";
import { FormLabel } from "@ui/components/forms/form-control/FormLabel";
import { Input } from "@ui/components/forms/input/Input";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash-es";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useRef, useState } from "react";
import { bool, object, string } from "yup";
import { styles } from "./styleFooterEmail";
import { noConsequentHyphens } from "@lib/validators/regex";

interface IFooterEmailSubscriptionProps {
    signUpNewsletter: {
        newsletterEmail: IFooter["fields"]["newsletterEmail"];
        newsletterHeader: IFooter["fields"]["newsletterHeader"];
        newsletterHeaderSuccess: IFooter["fields"]["newsletterHeaderSuccess"];
        newsletterDescription: IFooter["fields"]["newsletterDescription"];
        newsletterDescriptionSuccess: IFooter["fields"]["newsletterDescriptionSuccess"];
        newsletterPolicyAgreement: IFooter["fields"]["newsletterPolicyAgreement"];
        newsletterSubscribeButtonLabel: IFooter["fields"]["newsletterSubscribeButtonLabel"];
        newsletterEmailInputPlaceholder: IFooter["fields"]["newsletterEmailInputPlaceholder"];
        newsletterSubscribeButtonLabelSuccess: IFooter["fields"]["newsletterSubscribeButtonLabelSuccess"];
    };
}

export const FooterEmailSubscription: FC<IFooterEmailSubscriptionProps> = ({
    signUpNewsletter,
}) => {
    const { locale, basePath } = useRouter();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { getMultiple } = useMicrocopy();
    const errorToaster = useErrorToast(2000);
    const formRef = useRef();
    if (isEmpty(Object.values(signUpNewsletter).filter(Boolean))) return null;

    const header = signUpNewsletter?.newsletterHeader;
    const headerSuccess = signUpNewsletter?.newsletterHeaderSuccess;
    const description = signUpNewsletter?.newsletterDescription;
    const descriptionSuccess = signUpNewsletter?.newsletterDescriptionSuccess;
    const policyAgreement = signUpNewsletter?.newsletterPolicyAgreement;
    const emailInputPlaceholder = signUpNewsletter?.newsletterEmailInputPlaceholder;

    function getValidationSchema(headers) {
        return object().shape({
            email: string()
                .required(headers.requiredField)
                .matches(noConsequentHyphens, headers.email)
                .email(headers.email)
                .max(35, headers.maxCharacters)
                .concat(checkValidEmailDomain(headers.email)),
            ...(policyAgreement && {
                policy: bool().oneOf([true], headers.policy),
            }),
        });
    }

    const microcopies = (() => {
        return getMultiple(
            CommonCMS.global,
            [
                CommonCMS.email,
                CommonCMS.subscribeButton,
                CommonCMS.error.email,
                CommonCMS.error.requiredFieldError,
                CommonCMS.error.general.title,
                CommonCMS.error.general.description,
                CommonCMS.error.maxCharacters,
                CommonCMS.requiredLabel,
            ],
            { replacements: commonReplacements.maxEmailCharacters }
        );
    })();
    const headers = {
        email: microcopies[CommonCMS.email],
        errors: {
            email: microcopies[CommonCMS.error.email],
            requiredField: microcopies[CommonCMS.error.requiredFieldError],
            ...(policyAgreement && {
                policy: microcopies[CommonCMS.error.requiredFieldError],
            }),
            maxCharacters: microcopies[CommonCMS.error.maxCharacters],
        },
    };
    const formId = "subscriptionForm";
    const signUpSuccess = () => {
        return (
            <Flex
                direction={"column"}
                pt="2"
                w={"100%"}
                minHeight="332px"
                justifyContent={"space-between"}
            >
                <Box
                    textAlign={"left"}
                    sx={{
                        fontWeight: "bold",
                    }}
                    data-testid="subscription-footer-header"
                >
                    <Fade in={true} key={"header"}>
                        <Box color="white" textTransform={"uppercase"} fontWeight="bold">
                            {renderRichTextToComponent(removeEmptyContent(header))}
                        </Box>
                    </Fade>
                </Box>
                <Box py={[2, 2, 2, 2]} mt={[7, 7]}>
                    <Fade in={true} key={"header-success"}>
                        <Box color="white">
                            {renderRichTextToComponent(removeEmptyContent(headerSuccess))}
                        </Box>
                    </Fade>
                </Box>
                <Box mt={[5, 5]}>
                    <Fade in={true} key={"description-success"}>
                        <Box color="white">{renderRichTextToComponent(descriptionSuccess)}</Box>
                    </Fade>
                </Box>
            </Flex>
        );
    };
    const signUp = () => {
        return (
            <Flex
                direction={"column"}
                pt="2"
                w={"100%"}
                minHeight="332px"
                flex={1}
                justifyContent="space-between"
            >
                <Box
                    textAlign={"left"}
                    sx={{ fontWeight: "bold" }}
                    data-testid="subscription-footer-header"
                >
                    <Fade in={true} key={"header"}>
                        <Box color="white" textTransform={"uppercase"} fontWeight="bold">
                            {renderRichTextToComponent(removeEmptyContent(header))}
                        </Box>
                    </Fade>
                </Box>
                <Box data-testid="subscription-footer-description">
                    {!isSuccess ? (
                        <Fade in={true} key={"description"}>
                            <Box color="white" alignSelf="center">
                                {renderRichTextToComponent(description)}
                            </Box>
                        </Fade>
                    ) : null}
                </Box>
                <Formik
                    validationSchema={getValidationSchema(headers.errors)}
                    initialValues={{
                        email: "",
                        ...(policyAgreement && { policy: false }),
                    }}
                    innerRef={formRef}
                    onSubmit={async (values) => {
                        if (isLoading) return;
                        const currentval = values.email;
                        setIsLoading(true);
                        values.email = "";
                        const result = await FetchService.post<any, any>(
                            `${basePath}/api/subscription`,
                            {
                                email: currentval,
                                newsletterKey: Consent.newsletter,
                                privacyPolicyKey: Consent.privacyPolicy,
                                termsAndConditionsKey: Consent.termsAndConditions,
                                locale,
                            }
                        );
                        setIsLoading(false);
                        if (result.ok) {
                            AnalyticsClient.newsletterSubscribe(currentval);
                            setIsSuccess(true);
                            setTimeout(() => {
                                setIsSuccess(false);
                            }, 3000);
                        } else {
                            errorToaster({
                                title: microcopies[CommonCMS.error.general.title],
                                description: microcopies[CommonCMS.error.general.description],
                            });
                            setIsSuccess(false);
                            setIsLoading(false);
                            values.email = currentval;
                        }
                    }}
                >
                    {({ errors, touched }) => {
                        return (
                            <Form
                                id={formId}
                                noValidate={true}
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 14,
                                }}
                            >
                                <Flex
                                    gap={[2, 5, 5, 5]}
                                    width={"100%"}
                                    sx={{ ...styles.emailFormContainer }}
                                >
                                    <FormControl
                                        isRequired
                                        isInvalid={(!!errors.email && touched.email) as boolean}
                                    >
                                        <FormErrorMessage sx={{ color: colors.errorOnBlack }}>
                                            {errors.email}
                                        </FormErrorMessage>
                                        <Field
                                            as={Input}
                                            id={"email"}
                                            name={"email"}
                                            type={"email"}
                                            fontSize={["mobileH1", "tabletH1"]}
                                            fontWeight="bold"
                                            placeholder={emailInputPlaceholder}
                                            color={
                                                Boolean(errors.email) && Boolean(touched.email)
                                                    ? "errorOnBlack"
                                                    : "white"
                                            }
                                            background="black !important"
                                            height={"100% !important"}
                                            _placeholder={{
                                                color: "white",
                                                opacity: "100%",
                                            }}
                                            sx={
                                                Boolean(errors.email) && Boolean(touched.email)
                                                    ? { ...styles.fieldErrorStyle }
                                                    : { ...styles.fieldStyle }
                                            }
                                        />
                                        <FormLabel
                                            color={
                                                Boolean(errors.email) && Boolean(touched.email)
                                                    ? "errorOnBlack"
                                                    : "white"
                                            }
                                            htmlFor="email"
                                        >
                                            {microcopies[CommonCMS.requiredLabel]}
                                        </FormLabel>
                                    </FormControl>
                                    <Button
                                        aria-label={microcopies[CommonCMS.subscribeButton]}
                                        sx={{ ...styles.signUpButton }}
                                        bg="black !important"
                                        type={"submit"}
                                        form={formId}
                                        rightIcon={
                                            <ECCOIcon
                                                name="arrow-newsletter-signup"
                                                boxSize={["20", "24"]}
                                            />
                                        }
                                        disabled={isLoading}
                                        isLoading={isLoading}
                                    />
                                </Flex>
                                {policyAgreement ? (
                                    <FormControl
                                        isRequired
                                        isInvalid={!!errors.policy && (touched.policy as boolean)}
                                    >
                                        <Flex alignItems={"left"} gap={3} mt={[2, 6]}>
                                            <Box>
                                                <Field
                                                    as={Checkbox}
                                                    id="policy"
                                                    name="policy"
                                                    bg="transparent"
                                                    sx={{ ...styles.policyCheckBox }}
                                                />
                                            </Box>
                                            <FormLabel
                                                htmlFor="policy"
                                                pt={[0, 1]}
                                                sx={{
                                                    m: 0,
                                                    "& p": { ...styles.policyAgreementLabelP },
                                                    "& span": {
                                                        ...styles.policyAgreementLabelSpan,
                                                    },
                                                }}
                                            >
                                                {renderRichTextToComponent(policyAgreement)}
                                            </FormLabel>
                                        </Flex>
                                        <Box height={4}>
                                            <FormErrorMessage
                                                sx={{ color: colors.errorOnBlack }}
                                                mt={0}
                                            >
                                                {errors.policy}
                                            </FormErrorMessage>
                                        </Box>
                                    </FormControl>
                                ) : null}
                            </Form>
                        );
                    }}
                </Formik>
            </Flex>
        );
    };
    return (
        <Flex
            bg="black"
            direction={"column"}
            px={["layoutMargin.mobile", "layoutMargin.tab", "layoutMargin.desktop"]}
            pt="2"
            minHeight="332px"
        >
            {isSuccess ? signUpSuccess() : signUp()}
        </Flex>
    );
};
