export const clearCustomerServiceIcon = (customerServiceData: any) => {
    const clearIcon = (item) => {
        if (item?.data?.target?.fields?.icon) {
            delete item.data.target.fields.icon;
        } else if (item && typeof item === "object") {
            Object.values(item).forEach(clearIcon);
        }
    };

    customerServiceData?.content?.forEach(clearIcon);
    return customerServiceData;
};
