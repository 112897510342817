import USPbanner from "@components/ui/USPbanner/USPbanner";
import type { ISiteSettings } from "@contentful-api/types/contentful";
import type { PropsWithChildren, ReactNode } from "react";
import Footer from "./footer/Footer";
import dynamic from "next/dynamic";
import { PageNavigation } from "@components/cms/page-navigation/PageNavigation";
import { getPagePathByLocale, myAccountPage } from "@lib/constants/pagePaths";
import { deserialize } from "@ungap/structured-clone";
import type { StoreState } from "@store/index";
import { useStore } from "@store/index";
import type { DefaultPageProps } from "@interfaces/PageInterfaces";
import { useRouter } from "next/router";
import { useAuth } from "@lib/auth/useAuth";

const Navigation = dynamic(() => import("./navigation/Navigation").then((mod) => mod.Navigation));

export type MyAccountLayoutProps = {
    siteSettings: ISiteSettings;
    children?: ReactNode;
    pageProps: DefaultPageProps;
};

export const MyAccountLayout = ({
    siteSettings,
    children,
    pageProps,
}: PropsWithChildren<MyAccountLayoutProps>) => {
    const { locale } = useRouter();
    const { isAuthenticated } = useAuth();

    const { serializedPage } = pageProps;
    const store = useStore<StoreState>();

    const {
        me: { customer },
    } = store.data;

    const firstName = customer?.firstName || "";
    const lastName = customer?.lastName || "";
    const navigationTitle = `${firstName} ${lastName}`.trim();

    const page = deserialize(serializedPage);
    const pageNavigationLinks = page?.fields?.pageNavigation;

    return (
        <>
            {siteSettings?.fields?.uspBanner && (
                <USPbanner uspBanner={siteSettings?.fields?.uspBanner} />
            )}
            <Navigation navigation={siteSettings?.fields?.mainNavigation} />
            {pageNavigationLinks && isAuthenticated && (
                <PageNavigation
                    pageNavigationLinks={pageNavigationLinks}
                    title={navigationTitle}
                    basePath={getPagePathByLocale(myAccountPage, locale)}
                />
            )}
            {children}
            <Footer footerData={siteSettings?.fields?.footer} />
        </>
    );
};
