import type {
    AddProductAndRemoveOldestMutationVariables,
    AddProductToWishlistMutationVariables,
    ChangeProductSizeInWishlistMutationVariables,
    ChangeQuantityInWishlistMutationVariables,
    RemoveProductFromWishlistMutationVariables,
} from "@graphql/generated/components";
import {
    useAddProductAndRemoveOldestMutation,
    useAddProductToWishlistMutation,
    useChangeProductSizeInWishlistMutation,
    useChangeQuantityInWishlistMutation,
    useRemoveProductFromWishlistMutation,
} from "@graphql/generated/components";
import { updateMutation } from "../../helpers";
import { useStore } from "../../store";

export const useAddProductToWishlist = () => {
    const [_, execute] = useAddProductToWishlistMutation();
    const setData = useStore((state) => state.setWishlist);
    return updateMutation<AddProductToWishlistMutationVariables>(execute, setData);
};

export const useAddProductAndRemoveOldestFromWishlist = () => {
    const [_, execute] = useAddProductAndRemoveOldestMutation();
    const setData = useStore((state) => state.setWishlist);
    return updateMutation<AddProductAndRemoveOldestMutationVariables>(execute, setData);
};

export const useRemoveProductFromWishlist = () => {
    const [_, execute] = useRemoveProductFromWishlistMutation();
    const setData = useStore((state) => state.setWishlist);
    return updateMutation<RemoveProductFromWishlistMutationVariables>(execute, setData);
};

export const useUpdateProductQtyInWishlist = () => {
    const [_, execute] = useChangeQuantityInWishlistMutation();
    const setData = useStore((state) => state.setWishlist);
    return updateMutation<ChangeQuantityInWishlistMutationVariables>(execute, setData);
};

export const useUpdateProductSizeInWishlist = () => {
    const [_, execute] = useChangeProductSizeInWishlistMutation();
    const setData = useStore((state) => state.setWishlist);
    return updateMutation<ChangeProductSizeInWishlistMutationVariables>(execute, setData);
};
