import { space } from "@ui/design-tokens";
import { defaultUnderlineStyle } from "@ui/css/common";
export const styles = {
    container: {
        w: "100%",
        fontSize: "sm",
        fontWeight: "semibold",
        overflow: "hidden",
        footer: {
            bg: "white",
        },
    },
    mainContent: {
        flexWrap: "wrap",
        display: ["block", "inherit", "inherit", "inherit"],
        overflow: "hidden",
        pt: space["6"],
        flex: 1,
    },
    leftPart: {
        alignItems: "flex-start",
        flexBasis: ["50%", "50%", "50%", "30%"],
        mb: space["8"],
    },
    rightPart: {
        ml: [0, 0, 0, 20],
        alignItems: ["flex-start", "flex-start"],
        justifyContent: ["flex-start", "space-between"],
        flex: 1,
    },
    socialMediaContainer: {
        px: [1, 8],
        gap: 8,
        display: ["block", "flex", "flex", "flex"],
        my: 8,
        justifyContent: "space-between",
        minWidth: "23.33%",
    },
    authContainer: {
        pb: space["8"],
    },
    socialMediaWidget: {
        display: "flex",
    },
    contactText: {
        maxW: "504px",
        fontSize: ["md", "2xl"],
        color: "gray.800",
    },
    contactInformation: {
        maxW: "217px",
        maxH: "76px",
        color: "gray.800",
        "& p:first-of-type": {
            color: "black",
        },
        fontSize: [
            "mobileBodyTextNormal",
            "mobileBodyTextNormal",
            "mobileBodyTextNormal",
            "desktopBodyTextNormal",
        ],
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "19.5px",
        letterSpacing: "0.2px",
        mb: space["8"],
        "& button": {
            color: "black",
            ...defaultUnderlineStyle,
        },
    },
    customerService: {
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: "bold",
        letterSpacing: "0.2px",
        maxW: "234px",
        color: "black",
        "& p:first-of-type": {
            color: "black",
        },
        "& button": {
            color: "black",
            ...defaultUnderlineStyle,
        },
    },
    privacyLocationContainer: {
        px: 0,
        py: [2, 9],
        mb: [9],
        justifyContent: ["flex-start", "space-between"],
        flexDirection: ["column-reverse", "column"],
        lineHeight: ["normal"],
    },
    privacy: {
        my: [2, 0],
        fontSize: "xs",
        color: "black",
        display: "flex",
    },
    divider: {
        borderColor: "gray.100",
    },
    group: {
        display: "flex",
        flexDirection: "column",
        fontWight: "medium",
        flexBasis: ["50%", "50%", "50%", "23.33%"],
        mb: space["8"],
        w: [space["39"], , ,],
    },
    groupCapture: {
        fontSize: "sm",
        fontWeight: "bold",
        pb: space["2"],
    },
    link: {
        mt: space["2"],
        textDecoration: "none",
        color: "black",
        width: "fit-content",
        _hover: {
            "--b": "100%",
            ...defaultUnderlineStyle,
        },
        _focus: {
            "--b": "100%",
            ...defaultUnderlineStyle,
        },
        _active: {
            ...defaultUnderlineStyle,
        },
    },
};
