import type { ILink, IPagePath } from "@contentful-api/types/contentful";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { linkTypeGuard } from "../utils/linkTypeGuard";

const HIDDEN_ROUTES = ["/mi-cuenta/direcciones", "/my-account/addresses"];

type Config = {
    links: (ILink | IPagePath)[];
};

export const useLink = ({ links }: Config) => {
    const { asPath } = useRouter();
    const [activeLink, setActiveLink] = useState<number | null>(null);

    const getSlug = (link: ILink | IPagePath) =>
        linkTypeGuard(link)
            ? link?.fields?.internalLink?.fields?.completeSlug
            : link?.fields?.completeSlug;

    const onChangeActiveLink = (index: number) => () => setActiveLink(index);

    const isAddressesLink = (link: ILink | IPagePath) => HIDDEN_ROUTES.includes(getSlug(link));

    useEffect(() => {
        links.forEach((link, index) => {
            const decodedPath = decodeURI(asPath);

            const slug = getSlug(link);

            if (!slug) return;

            if (decodedPath.includes(slug)) {
                setActiveLink(index);
            }
        });
    }, [asPath]);

    return { activeLink, isAddressesLink, onChangeActiveLink };
};
