import type { FlexProps } from "@ui/components/layout/flex/Flex";

export const pageNavigationScrollerStyles: FlexProps["sx"] = {
    scrollSnapType: "x mandatory",
    msOverflowStyle: "none" /* for Internet Explorer, Edge */,
    scrollbarWidth: "none" /* for Firefox */,
    /* Enable Safari touch scrolling physics which is needed for scroll snap */
    WebkitOverflowScrolling: "touch",
    color: "inherit",
    "::-webkit-scrollbar": {
        width: ["2px", "4px"],
        height: ["2px", "16px"],
    },
    overflowX: ["scroll", "auto"],
    overflowY: "hidden",
    "::-webkit-scrollbar-track": {
        borderBottom: "2px solid",
        borderColor: "gray.200",
        marginLeft: 2,
        marginRight: 2,
    },
    "::-webkit-scrollbar-thumb": {
        borderBottom: "2px solid",
        borderColor: "black",
    },
    w: "full",
    p: "1",
};

export const logOutStyle = {
    textDecoration: "none",
    fontWeight: "bold",
    flexShrink: 0,
    backgroundColor: "white",
    color: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "full",
    h: 10,
    px: 3,
    py: 4,
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
    fontSize: ["desktopBodyB2", "mobileBodyB2"],
    mb: 3,
    _hover: {
        bg: "white !important",
        color: "black !important",
    },
};
